import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Card,
  CardActions,
  Typography,
  CardMedia,
  CardActionArea,
  Tooltip,
  Icon,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import ShowcaseCard from "./SessionCard";
import ContentLoader from "react-content-loader";
import SessionPromo from "./SessionPromo";

const log = false;

export default function Order(props) {
  const cls = useStyles();

  const [sessionid] = useState(
    props.sessionid || props.match.params.sessionid || null
  );
  const [session, setSession] = useState(null);

  useEffect(() => {
    async function fetchSession() {
      const response = await axios({
        url: process.env.REACT_APP_GRAPHQL_URL,
        method: "post",
        data: {
          query: `query getSessionForPhotoSiteBySessionId($session_id: String) {
            sessions(where: {id: {_eq: $session_id}}) {
              id
              layout_id
              createdat
              photos(order_by: {sequence: asc}) {
                id
                name
                sequence
                smugmug_uri
                thumb_url
                thumb_height
                thumb_width
                thumb_size
                full_url
                full_height
                full_width
                full_size
                createdat
              }
            }
          }
          `,
          variables: { session_id: sessionid },
        },
        headers: {
          "content-type": "application/json",
        },
      });

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.sessions &&
        response.data.data.sessions.length > 0
      ) {
        setSession(response.data.data.sessions[0]);
        log && console.log(response);
      }
    }
    if (sessionid) fetchSession();
  }, [sessionid]);

  return (
    <div className={cls.margin}>
      {session && session.photos ? (
        <>
          <Grid container spacing={5} justify="left">
            <Grid item xl={12} xs={12} key={"promo"}>
              <SessionPromo session={session} />
            </Grid>
            <Grid item xl={12} xs={12} key={session.id}>
              <ShowcaseCard
                autoOpen={false}
                photos={session.photos.filter(
                  (photo) =>
                    photo.name !== "sign.png" && photo.name !== "strip.jpg"
                )}
                message={session.photos.find(
                  (photo) => photo.name === "sign.png"
                )}
                strip={session.photos.find(
                  (photo) => photo.name === "strip.jpg"
                )}
                title={moment(session.createdat).fromNow()}
                caption={session.id}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <ContentLoader
          speed={2}
          width={400}
          height={460}
          viewBox="0 0 400 460"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <circle cx="31" cy="31" r="15" />
          <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
          <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
          <rect x="0" y="60" rx="2" ry="2" width="600" height="400" />
        </ContentLoader>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    flexWrap: "nowrap",
  },
  valTxt: {
    width: "100%",
    textAlign: "left",
    lineHeight: 1.25,
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: "left",
    lineHeight: 1.25,
    fontSize: "14px",
    fontWeight: 400,
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  lineBreak: {
    width: "100%",
    height: "1px",
    marginTop: "6px",
    marginBottom: "8px",
    background: "#eee",
  },
  notFound: {
    padding: theme.spacing(4),
    border: "1px solid #ddd",
    borderRadius: "8px",
    marginLeft: "auto",
    marginRight: "auto",
    background: "#fff",
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: "21px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));
