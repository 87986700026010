import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const SessionPromoVariantA = React.memo(function SessionPromoVariantA({
  session = {},
  onPromoClick,
  ...props
}) {
  const cls = useStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [eventType, setEventType] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleAdClick = () => {
    setFormOpen(true);
    onPromoClick();
  };

  const handleClose = () => {
    setFormOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showFeedback = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      eventType: formData.get("eventType"),
      eventDate: formData.get("eventDate"),
      phone: formData.get("phone"),
    };

    try {
      const response = await fetch("/.netlify/functions/send-reminder-email", {
        method: "POST",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        setFormOpen(false);
        showFeedback("Thank you! Enjoy the rest of your event!", "success");
      } else {
        const errorText = await response.text();
        console.error("Error:", errorText);
        showFeedback("Oops! Something went wrong. Please try again later.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showFeedback("Oops! Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <>
      <Paper elevation={3} className={cls.card}>
        <div className={cls.cardContent}>
          <Typography variant="h4" className={cls.heading}>
            Loved Your Photos?
          </Typography>
          <Typography variant="h5" className={cls.subheading}>
            Create Your Own Event Magic!
          </Typography>
          <Typography variant="body1" className={cls.offer}>
            Book your next event with us and get
            <Typography variant="h6" component="span" className={cls.discountCode}>
              $20 off
            </Typography>
            with code <strong>HelloGuest</strong>!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={cls.remindButton}
            onClick={handleAdClick}
          >
            Get My Discount
          </Button>
        </div>
      </Paper>

      <Dialog open={formOpen} onClose={handleClose} fullWidth maxWidth="xs" classes={{ paper: cls.dialogPaper }}>
        <DialogTitle className={cls.dialogTitle}>Get Your Discount Code</DialogTitle>
        <DialogContent className={cls.dialogContent}>
          <Typography variant="body2" gutterBottom className={cls.discountCode}>
            Use code <strong>HelloGuest</strong> when booking to get $20 off!
          </Typography>
          <form id="reminder-form" onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Your Name"
              fullWidth
              required
              margin="dense"
              variant="outlined"
              className={cls.input}
              InputProps={{ classes: { root: cls.inputRoot, focused: cls.inputFocused } }}
              InputLabelProps={{ classes: { root: cls.inputLabel, focused: cls.inputLabelFocused } }}
            />
            <TextField
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              required
              margin="dense"
              variant="outlined"
              className={cls.input}
              InputProps={{ classes: { root: cls.inputRoot, focused: cls.inputFocused } }}
              InputLabelProps={{ classes: { root: cls.inputLabel, focused: cls.inputLabelFocused } }}
            />
            <FormControl fullWidth margin="dense" variant="outlined" className={cls.input}>
              <InputLabel id="event-type-label">Type of Event</InputLabel>
              <Select
                labelId="event-type-label"
                name="eventType"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
                label="Type of Event"
                classes={{ root: cls.selectRoot, icon: cls.selectIcon }}
              >
                <MenuItem value="wedding">Wedding</MenuItem>
                <MenuItem value="birthday">Birthday</MenuItem>
                <MenuItem value="corporate">Corporate Event</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="eventDate"
              label="Tentative Event Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
                classes: { root: cls.inputLabel, focused: cls.inputLabelFocused }
              }}
              margin="dense"
              variant="outlined"
              className={cls.input}
              InputProps={{ classes: { root: cls.inputRoot, focused: cls.inputFocused } }}
            />
            <TextField
              name="phone"
              label="Phone Number (Optional)"
              type="tel"
              fullWidth
              margin="dense"
              variant="outlined"
              className={cls.input}
              InputProps={{ classes: { root: cls.inputRoot, focused: cls.inputFocused } }}
              InputLabelProps={{ classes: { root: cls.inputLabel, focused: cls.inputLabelFocused } }}
            />
          </form>
        </DialogContent>
        <DialogActions className={cls.dialogActions}>
          <Button onClick={handleClose} className={cls.buttonSecondary}>
            Maybe Later
          </Button>
          <Button form="reminder-form" type="submit" variant="contained" className={cls.buttonPrimary}>
            Get Code
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} elevation={6} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
});

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#f8f9fa",
    borderRadius: "16px",
    padding: theme.spacing(3),
    margin: "16px auto",
    maxWidth: "100%",
    boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      maxWidth: "600px",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "5px",
      background: "linear-gradient(90deg, #ff6b6b, #feca57, #48dbfb, #ff9ff3)",
    },
  },
  cardContent: {
    textAlign: "center",
  },
  heading: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: "#2d3436",
    fontSize: "1.75rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "2.125rem",
    },
  },
  subheading: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    color: "#636e72",
    fontSize: "1.5rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "1.8rem",
    },
  },
  offer: {
    fontSize: "1.1rem",
    marginBottom: theme.spacing(3),
    color: "#2d3436",
  },
  discountCode: {
    color: "#e17055",
    display: "inline",
    margin: `0 ${theme.spacing(0.5)}px`,
    fontWeight: 700,
  },
  remindButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 4),
    fontSize: "1.1rem",
    borderRadius: "30px",
    textTransform: "none",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 6px 8px rgba(0,0,0,0.15)",
    },
  },
  dialogPaper: {
    borderRadius: 16,
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(3, 2, 2),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  inputRoot: {
    borderRadius: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  inputFocused: {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
  },
  inputLabel: {
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  inputLabelFocused: {},
  selectRoot: {
    borderRadius: 8,
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  buttonPrimary: {
    borderRadius: 20,
    textTransform: 'none',
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonSecondary: {
    borderRadius: 20,
    textTransform: 'none',
    padding: theme.spacing(1, 3),
    color: theme.palette.text.secondary,
  },
}));

export default SessionPromoVariantA;